.MainDeviceWithFooter {

    height: 100%;
    position: relative;
    color: var(--themeFontColor);
    height: 100%;
    width: 100%;

    .deviceMainContainer {
        box-sizing: border-box;
        // font-family: 'Open Sans', sans-serif;

        .deviceMain {
            padding: 1rem;
            .datepiker{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }
            .tableMain{
                display: flex;
                padding-top: 2rem;
                justify-content: center;
                align-self: center;
                table,
                tr,
                th,
                td{
                    border: 1px solid gray;
                    border-collapse: collapse;
                    padding: 0.5rem;
                    align-self: center;
                    text-align: center;
                }
                th{
                    background-color: var(--backColor);
                    color: white;
                    text-transform: uppercase;
                }
                .titleMain{
                    display: flex;
                    gap: 0.5rem;
                    .editDelete{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        .EditBtn{
                            width: 3rem;
                            border: transparent;
                            color: white;
                            background-color: transparent;
                        }
                        .EditBtn:hover{
                            transform: scale(1.2);
                        }
                        .DelBtn{
                            background-color: transparent;
                            align-self: center;  
                            color: white;                      
                            border: transparent;
                            width: 3rem;
                        }
                        .DelBtn:hover{
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .areaChartSteamFlow{
                padding-top: 3rem;
            }

            .deviceHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1rem;

                .emailAdd{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }

                .addBuilingBtn {
                    border: transparent;
                    background-color: green;
                    color: white;
                    font-weight: bold;

                }

                .addBuilingBtn:hover {
                    border: transparent;
                    color: white;
                }

                .headleft {
                    display: flex;

                }
            }


            .deviceCard {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                padding: 1rem;
                border-radius: 10px;
                width: 100%;
                align-items: center;
                align-self: center;
                .devicemain {
                    /* background-color: rgb(228, 220, 199); */
                    line-height: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                    height: 26rem;
                    width: 17rem;
                    border-radius: 5px;
                    /* background-color: #3c3f431c; */
                    align-items: center;
                    margin: 1rem;
                    text-align: center;
                    font-size: 12px;
                    overflow: hidden;
                    font-size: 1.2rem;

                    .industoryimg {
                        height: 10rem;
                        width: 15rem;
                        margin: 1rem;
                        overflow: hidden;
                        transition: 0.5s all ease-in-out;
                        border-radius: 10px;
                        cursor: pointer;
                        object-fit: cover;
                    }

                    .deviceName {
                        cursor: pointer;
                        .deviceID{
                            font-size: 13px;
                        }
                    }

                    .industoryimg:hover {
                        transform: scale(1.05);
                    }

                    .MainReadings {
                        cursor: pointer;
                        font-size: 13px;
                        // height: 3rem;
                        display: flex;
                        flex-direction: column;
                        .Reading{
                            display: flex;
                            width: 15rem;
                            .left{
                                text-align: start;
                                width: 50%;
                            }
                        }
                    }

                    .cartBtns {
                        display: flex;
                        padding-top: 1rem;
                        gap: 0.5rem;


                        .cartBtn {
                            width: 7rem;
                            border: transparent;
                            color: white;
                            font-weight: bold;
                        }
                    }
                }
            }


        }
    }

}